<template>
  <r-container class="fill d-flex flex-column">
    <r-message :items="errors" type="error"></r-message>
    <r-row class="h-center" v-if="step === 1">
      <div class="login-holder text-center">
        <r-form ref="form" v-model="valid">
          <r-text-input
            :label="$t('name')"
            v-model="info.name"
            :rules="['required']"
          ></r-text-input>
          <r-text-input
            :label="$t('family')"
            v-model="info.family"
            :rules="['required']"
          ></r-text-input>
          <r-tel defaultCountry="IR" v-model="info.tel" required></r-tel>

          <r-row class="no-gutters mt-5">
            <r-col class="col-auto">
              <r-switch
                v-model="accept_law"
                required
                :label="$t('accept', 'renusify')"
              ></r-switch>
            </r-col>
            <r-col class="col-auto">
              <r-btn text class="color-info-text" :to="{ name: 'laws' }">{{
                $t("accept_law", "renusify")
              }}</r-btn>
            </r-col>
          </r-row>
          <r-row>
            <transition name="scale" mode="out-in">
              <r-col>
                <r-btn
                  :disabled="!valid || !info.tel.phone || !accept_law"
                  :loading="loading"
                  @click.stop="send"
                  class="color-success my-3 send-login"
                  block
                  rounded
                >
                  {{ $t("login", "renusify") }}
                </r-btn>
              </r-col>
            </transition>
          </r-row>
        </r-form>
      </div>
    </r-row>
    <r-row v-if="step === 2">
      <r-col>
        <r-row class="h-center">
          <r-col class="col-12 sm-8 md-5">
            <r-btn @click.stop="changeShow(1)" block rounded>
              <r-icon v-html="$r.icons.arrow_left"></r-icon>
              {{ $t("edit_phone", "renusify") }}
            </r-btn>
          </r-col>
        </r-row>
        <r-row class="h-center">
          <div class="login-holder text-center">
            <h1 class="mb-3 display-4">
              {{ $t("two_step_code_form", "renusify") }}
            </h1>
            <div class="my-3">
              {{ $t(["activation_message", [info.tel.phone]], "renusify") }}
            </div>

            <r-form v-model="valid_code">
              <r-mask-input
                :label="$t('two_step_code', 'renusify')"
                class="mt-6"
                mask="N N N N N N"
                required
                v-model="info.two_step_code"
              ></r-mask-input>
              <r-btn
                :disabled="!valid_code"
                :loading="loading"
                @click.stop="send_code"
                block
                class="my-3 color-success send-code"
                rounded
              >
                {{ $t("send", "renusify") }}
              </r-btn>
              <r-count-down :time="countTime" @end="DisableResendBtn = false">
                <template v-slot="props">
                  <r-btn
                    :disabled="DisableResendBtn"
                    :loading="LoadingResendBtn"
                    @click.stop="resend_code"
                    block
                    class="mb-3 color-info"
                    rounded
                  >
                    {{
                      $t(
                        [
                          "resend_code_btn",
                          [`${props.minutes}:${props.seconds}`],
                        ],
                        "renusify"
                      )
                    }}
                  </r-btn>
                </template>
              </r-count-down>
            </r-form>
          </div>
        </r-row>
      </r-col>
    </r-row>
  </r-container>
</template>

<script>
export default {
  data() {
    return {
      login_phone: true,
      test: "",
      e1: true,
      accept_law: false,
      setting: {},
      loading: false,
      loading2: false,
      info: {
        name: null,
        family: null,
        tel: {
          phone: "",
          country_code: "",
        },
        two_step_code: "",
      },
      valid: false,
      valid_code: false,
      valid_info: false,
      errors: {},
      alertShow: false,
      step: 1,
      DisableResendBtn: true,
      LoadingResendBtn: false,
      countTime: 2 * 60 * 1000,
    };
  },
  methods: {
    changeShow(i) {
      this.step = i;
    },
    country_code(e) {
      this.info.country_code = e;
    },
    send() {
      this.loading = true;
      this.$axios
        .post("home/doctor/create", {
          name: this.info.name,
          family: this.info.family,
          phone: this.info.tel.phone,
          country_code: this.info.tel.country_code,
        })
        .then(
          () => {
            this.loading = false;
            this.step = 2;
          },
          (res) => {
            this.loading = false;
            if (res.response.data) {
              if (res.response.data.errors) {
                this.errors = res.response.data.errors;
                this.alertShow = true;
              }
            }
          }
        );
    },
    go() {
      this.step = 1;
      this.info = {
        tel: {
          phone: "",
          country_code: "",
        },
        two_step_code: "",
      };
      window.location = "/doctor/information";
    },
    send_code() {
      this.loading = true;
      this.$axios
        .post("user/two_step_code", {
          phone: this.info.tel.phone,
          country_code: this.info.tel.country_code,
          two_step_code: this.info.two_step_code,
        })
        .then(
          ({ data }) => {
            this.$storage.set("auth.token", data.token);
            this.$r.store.user = {
              login: data.login,
              info: data.info,
            };
            this.loading = false;
            this.go();
            this.$emit("success", true);
          },
          (res) => {
            this.$emit("failed", true);
            this.loading = false;
            if (res.response.data) {
              if (res.response.data.errors) {
                this.errors = res.response.data.errors;
                this.alertShow = true;
              }
            }
          }
        );
    },
    resend_code() {
      this.countTime = 1;
      this.LoadingResendBtn = true;
      this.$axios
        .post("user/resend_two_step_code", {
          phone: this.info.tel.phone,
          country_code: this.info.tel.country_code,
        })
        .then(
          () => {
            this.LoadingResendBtn = false;
            this.DisableResendBtn = true;
            this.countTime = 60 * 1000;
            this.errors = [];
          },
          (res) => {
            if (res.response.data.errors) {
              this.errors = res.response.data.errors;
              this.alertShow = true;
              if (res.response.data.errors.phone) {
                this.countTime = res.response.data.errors.phone[1][0] * 1000;
              }
            }
            this.LoadingResendBtn = false;
            this.DisableResendBtn = true;
          }
        );
    },
  },
};
</script>
<style>
.login-holder {
  width: 500px;
  max-width: 100%;
  padding: 5px;
}
</style>
